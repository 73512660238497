import React, { FC } from 'react';

export interface IStepWizardProps {
    children: React.ReactElement[];
    switchToStep: number;
}

export const StepWizard: FC<IStepWizardProps> = (props) => {
    const { children, switchToStep } = props;
    return (<div>{children[switchToStep]}</div>);
};