import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { ICommonProps } from "../../ICommonProps";
import { classNames } from "../../../styles/MergeStyleSets";
import { MessageBar, TextField } from "@fluentui/react";
import { t } from "i18next";
import { IMessageItem } from "../../../model/model";
import { useMessages } from "../../../context/MessagesContext";
import { getAttachmentsCount } from "../../../helpers/Mapper";
import { Constants } from "../../../helpers/Constants";
import { MessagesActionType } from "../../../context/MessagesReducer";

interface IMessageNewDocumentViewProps extends ICommonProps {
    selectedMessages?: IMessageItem[];
}

export interface IMessageNewDocumentViewRef {
    validate(): boolean;
}

export const MessageNewDocumentView = forwardRef<IMessageNewDocumentViewRef, IMessageNewDocumentViewProps>((props, ref) => {
    const { messageState, dispatch } = useMessages();
    const [showDocumentTitleError, setShowDocumentTitleError] = useState(false);
    const requiredError = t('Required');

    const onDocumentTitleChange = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        dispatch({ type: MessagesActionType.ArchiveMessages, payload: { caseDocumentData: { ...messageState.caseDocumentData, documentTitle: newValue } } });
        setShowDocumentTitleError(newValue?.trim() === "");
    }, [dispatch, messageState.caseDocumentData]);

    useImperativeHandle(ref, () => ({
        validate: () => {
            if (!messageState.caseDocumentData.documentTitle) {
                setShowDocumentTitleError(true);
                return false;
            }
            return true;
        }
    }));

    return <div className={classNames.messageNewDocumentViewContainer}>
        <MessageBar>
            {t('1 document card will be created  with the')} {getAttachmentsCount(messageState, props.selectedMessages)} {t('files in 360')}
        </MessageBar>
        <TextField label={t('Document title')}
            value={messageState.caseDocumentData.documentTitle ?? ''}
            maxLength={Constants.DocumentTitleMaxLength}
            required
            multiline
            resizable={false}
            onChange={onDocumentTitleChange}
            errorMessage={showDocumentTitleError ? requiredError : undefined} />
    </div>;
});