import { ComboBox, IComboBox, IComboBoxOption, IconButton, SelectableOptionMenuItemType, Stack } from '@fluentui/react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useP360 } from '../../context/P360Context';
import { Constants } from '../../helpers/Constants';
import { EnumSearchCriteria, ISearchCase } from '../../model/model';
import { ICommonProps } from '../ICommonProps';
import { t } from "i18next";
import { useMessages } from '../../context/MessagesContext';
import { MessagesActionType } from '../../context/MessagesReducer';
import SearchOptionButton from '../common/SearchOptionButton';

interface IChatSearchboxProps extends ICommonProps {
    enumSearchCriteria: EnumSearchCriteria,
    searchBoxWidth: number,
    includeClosedCases: boolean,
    searchText?: string | null,
    searchErrorMessage?: string
}

export const ChatSearchbox: FC<IChatSearchboxProps> = (props) => {
    const { p360State } = useP360();
    const { messageState, dispatch } = useMessages();
    const casePlaceholder = t('Type here to search case');
    const documentPlaceholder = t('Type here to search document');
    const { enumSearchCriteria, searchBoxWidth, searchText, searchErrorMessage, dataProvider, includeClosedCases } = props;
    const [searchCriteria, setSearchCriteria] = useState<ISearchCase>(() => {
        return { searchCriteria: searchText ?? null, includeClosedCases: includeClosedCases };
    });
    const [comboboxOptions, setComboboxOptions] = useState<IComboBoxOption[]>([]);
    const [buttonText, setButtonText] = useState<string>(t('Case'));
    const [selectedEntityKey, setSelectedEntityKey] = useState<string | null>(() => {
        return searchText ?? null;
    });
    const [searchEntityName, setSearchEntityName] = useState<EnumSearchCriteria>(() => {
        if (enumSearchCriteria === EnumSearchCriteria.All)
            return EnumSearchCriteria.Case;
        return enumSearchCriteria;
    });
    const [comboboxPlaceholder, setComboboxPlaceholder] = useState<string>(casePlaceholder);
    const comboBoxRef = useRef<IComboBox>(null);

    const getCases = useCallback(() => {
        dataProvider?.P360?.getCases(searchCriteria).then((res) => {
            setComboboxOptions([]);
            const cases: IComboBoxOption[] = [];
            for (const element of res.data)
                cases.push({ key: element.CaseNumber, text: element.CaseNumber + ' - ' + element.Title });
            setComboboxOptions(cases);
            if (searchCriteria.searchCriteria && res.data.length > 0)
                comboBoxRef.current?.focus(true);
        });
    }, [dataProvider?.P360, searchCriteria]);

    const getDocuments = useCallback(() => {
        dataProvider?.P360?.searchDocuments({ searchText: searchCriteria.searchCriteria, startRow: 0, maxRow: 50 }).then((res) => {
            setComboboxOptions([]);
            const documents: IComboBoxOption[] = [];
            for (const element of res.data.DocumentsResult.Documents)
                documents.push({ key: element.DocumentNumber, text: element.DocumentNumber + ' - ' + element.Title, data: element });
            setComboboxOptions(documents);
            if (searchCriteria.searchCriteria && res.data.DocumentsResult.Documents.length > 0)
                comboBoxRef.current?.focus(true);
        });
    }, [dataProvider?.P360, searchCriteria.searchCriteria]);

    const getHistory = useCallback(() => {
        let entityName = "";
        switch (searchEntityName) {
            case EnumSearchCriteria.Case:
                entityName = EnumSearchCriteria.Case.toString();
                break;
            case EnumSearchCriteria.Document:
                entityName = EnumSearchCriteria.Document.toString();
                break;
            case EnumSearchCriteria.All:
                entityName = EnumSearchCriteria.Case.toString();
                break;
        }

        dataProvider?.P360?.getHistory({ entityName: entityName, startRow: 0, maxRow: 10, includeClosedCases: includeClosedCases }).then((res) => {
            if (res.data.length > 0) {
                const histories: IComboBoxOption[] = [];
                histories.push({ key: 'HistoryHeader', text: t('History'), itemType: SelectableOptionMenuItemType.Header });

                for (const element of res.data)
                    histories.push({ key: element.EntityNumber, text: element.EntityNumber + ' - ' + element.Title });
                setComboboxOptions(histories);
            }
        });
    }, [dataProvider?.P360, searchEntityName, includeClosedCases]);

    const isDocumentSearchFeatureEnabled = useCallback(() => {
        return p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.DocumentsSearch);
    }, [p360State.backendCapabilities?.Capabilities]);

    const onChangeCombo = useCallback((event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        if (option) {
            setSelectedEntityKey(option.key.toString());
            if (searchEntityName === EnumSearchCriteria.Case)
                dispatch({ type: MessagesActionType.ArchiveMessages, payload: { caseDocumentData: { ...messageState.caseDocumentData, caseNumber: option.key.toString() } } });
            else if (searchEntityName === EnumSearchCriteria.Document)
                dispatch({ type: MessagesActionType.ArchiveMessages, payload: { caseDocumentData: { ...messageState.caseDocumentData, documentNumber: option.key.toString() } } });
            return;
        }

        setComboboxOptions([]);
        setSearchCriteria({ searchCriteria: value ?? null, includeClosedCases: includeClosedCases });
    }, [dispatch, includeClosedCases, messageState.caseDocumentData, searchEntityName]);

    const caseSearchHandler = useCallback(() => {
        dispatch({ type: MessagesActionType.UpdateSearchCriteria, payload: { searchCriteria: EnumSearchCriteria.Case } });
        setButtonText(t('Case'));
        setComboboxPlaceholder(casePlaceholder);
        setSelectedEntityKey(null);
        setSearchCriteria({ searchCriteria: null, includeClosedCases: includeClosedCases });
        setComboboxOptions([]);
        setSearchEntityName(EnumSearchCriteria.Case);
    }, [casePlaceholder, dispatch, includeClosedCases]);

    const documentSearchHandler = useCallback(() => {
        dispatch({ type: MessagesActionType.UpdateSearchCriteria, payload: { searchCriteria: EnumSearchCriteria.Document } });
        setButtonText(t('Document'));
        setComboboxPlaceholder(documentPlaceholder);
        setSelectedEntityKey(null);
        setSearchCriteria({ searchCriteria: null, includeClosedCases: includeClosedCases });
        setComboboxOptions([]);
        setSearchEntityName(EnumSearchCriteria.Document);
    }, [dispatch, documentPlaceholder, includeClosedCases]);

    useEffect(() => {
        if (searchCriteria.searchCriteria) {
            if (searchEntityName === EnumSearchCriteria.Case)
                getCases();
            else if (searchEntityName === EnumSearchCriteria.Document && isDocumentSearchFeatureEnabled()) {
                setComboboxPlaceholder(documentPlaceholder);
                getDocuments();
            }
        }
        else if (p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.GetHistory))
            getHistory();
    }, [enumSearchCriteria, documentPlaceholder, getHistory, getCases, searchCriteria.searchCriteria, getDocuments, searchEntityName, isDocumentSearchFeatureEnabled, p360State.backendCapabilities?.Capabilities]);

    return <Stack horizontal horizontalAlign="start">
        <Stack>
            <SearchOptionButton
                enumSearchCriteria={enumSearchCriteria}
                buttonText={buttonText}
                enableDocumentSearchFeature={isDocumentSearchFeatureEnabled()}
                caseSearchHandler={caseSearchHandler}
                documentSearchHandler={documentSearchHandler}
            />
        </Stack>
        <Stack style={{ marginLeft: '-2px' }}>
            <ComboBox
                styles={{ root: { width: searchBoxWidth + 'px', maxWidth: '390px' }, optionsContainerWrapper: { maxWidth: '390px' } }}
                componentRef={comboBoxRef}
                placeholder={comboboxPlaceholder}
                selectedKey={selectedEntityKey}
                allowFreeform={true}
                autoComplete={'on'}
                options={comboboxOptions}
                onChange={onChangeCombo}
                errorMessage={searchErrorMessage}
            />
        </Stack>
        <Stack>
            <IconButton
                iconProps={{ iconName: 'Search' }}
                ariaLabel={t('Search')}
            />
        </Stack>
    </Stack>;
};