import { FormEvent, forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { Constants } from "../../../helpers/Constants";
import { ICommonProps } from "../../ICommonProps";
import { useP360 } from "../../../context/P360Context";
import { useId } from '@fluentui/react-hooks';
import { EnumCase, EnumSearchCriteria } from "../../../model/model";
import { ChoiceGroup, IChoiceGroupOption, IChoiceGroupOptionProps, IRenderFunction, TooltipHost } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { classNames, tooltipHostStyles } from "../../../styles/MergeStyleSets";
import { IMessageNewCaseViewRef, MessageNewCaseView } from "./MessageNewCaseView";
import { IMessageExistingCaseViewRef, MessageExistingCaseView } from "./MessageExistingCaseView";
import { useMessages } from "../../../context/MessagesContext";
import { MessagesActionType } from "../../../context/MessagesReducer";

interface IMessageCaseViewProps extends ICommonProps {
    isFileArchiveAction: boolean;
}

export interface IMessageCaseViewRef {
    validate(): boolean;
}

export const MessageCaseView = forwardRef<IMessageCaseViewRef, IMessageCaseViewProps>((props, ref) => {
    const { isFileArchiveAction } = props;
    const { messageState, dispatch } = useMessages();
    const newCaseViewRef = useRef<IMessageNewCaseViewRef>(null);
    const existingCaseViewRef = useRef<IMessageExistingCaseViewRef>(null);

    const { p360State } = useP360();
    const { t } = useTranslation();
    const tooltipId = useId('tooltip');
    const archiveOnExistingDocumentEnabled = isFileArchiveAction === true &&
        p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.ArchiveOnExistingDocument) === true &&
        p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.DocumentsSearch) === true;

    const disallowCaseCreation = p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.DisallowCaseCreation) === true;

    useImperativeHandle(ref, () => ({
        validate: () => {
            return (messageState.caseDocumentData.selectionNewOrExisting === EnumCase.NewCase ?
                newCaseViewRef.current?.validate() : existingCaseViewRef.current?.validate()) as boolean;
        }
    }));

    const newCaseOptionRender: IRenderFunction<IChoiceGroupOption & IChoiceGroupOptionProps> = useCallback((props, render) => {
        return (
            <TooltipHost content={t('User does not have permission to create a new case')} id={tooltipId} calloutProps={{ gapSpace: 0, hidden: !disallowCaseCreation }} styles={tooltipHostStyles}>
                {
                    render !== undefined ?
                        render(props) : null
                }
            </TooltipHost>
        );
    }, [t, tooltipId, disallowCaseCreation]);

    const onChange = useCallback((ev?: FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        switch (option?.key) {
            case '1':
                dispatch({
                    type: MessagesActionType.ArchiveMessages, payload: {
                        caseDocumentData: {
                            ...messageState.caseDocumentData,
                            caseTitle: "",
                            accessGroup: "",
                            selectionNewOrExisting: EnumCase.NewCase
                        }
                    }
                });
                dispatch({ type: MessagesActionType.UpdateSearchCriteria, payload: { searchCriteria: EnumSearchCriteria.Case } });
                return;
            case '2':
            default:
                dispatch({
                    type: MessagesActionType.ArchiveMessages, payload: {
                        caseDocumentData: {
                            ...messageState.caseDocumentData,
                            caseNumber: "",
                            documentNumber: "",
                            selectionNewOrExisting: EnumCase.ExistingCase
                        }
                    }
                });
                return;
        }
    }, [dispatch, messageState.caseDocumentData]);
    const options: IChoiceGroupOption[] = [
        { key: EnumCase.NewCase.toString(), text: t('New case'), disabled: disallowCaseCreation, onRenderField: newCaseOptionRender },
        { key: EnumCase.ExistingCase.toString(), text: archiveOnExistingDocumentEnabled ? t('Existing case or document') : t('Existing case') }
    ];

    return <div className={classNames.messageCaseViewContainer}>
        <div className={classNames.messageCaseViewHeader}>{t('Case Information')}</div>
        <div>
            {archiveOnExistingDocumentEnabled ? t('Do you want to create a new case or use existing case or document?') : t('Do you want to create a new case or use existing case?')}
        </div>
        <div>
            <ChoiceGroup selectedKey={messageState.caseDocumentData.selectionNewOrExisting} options={options} onChange={onChange} required={true} />
            {
                messageState.caseDocumentData.selectionNewOrExisting === EnumCase.NewCase ?
                    <MessageNewCaseView {...props} ref={newCaseViewRef} />
                    :
                    <MessageExistingCaseView {...props} ref={existingCaseViewRef} selectedCase={""} archiveOnExistingDocumentEnabled={archiveOnExistingDocumentEnabled} />
            }
        </div>
    </div>;
});