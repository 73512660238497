import { FC } from "react";
import { Features } from "../../config/Features";
import { classNames } from "../../styles/MergeStyleSets";
import { ICommonProps } from "../ICommonProps";
import { t } from "i18next";

const MeetingArchiving: FC<ICommonProps> = (props) => {
    if (Features.EnableMeetingArchiving) {
        return <blockquote className={classNames.errorBlockQuote}>
            <h3>{'Welcome to Teams Meeting Archiving app'}</h3>
        </blockquote>;
    } else {
        return <blockquote className={classNames.errorBlockQuote}>
            <h3>{t('Meeting Archiving is not supported in this version of 360°')}</h3>
        </blockquote>;
    }
};

export default MeetingArchiving;