import { forwardRef, useImperativeHandle, useState } from "react";
import { ICommonProps } from "../../ICommonProps";
import { classNames } from "../../../styles/MergeStyleSets";
import { EnumSearchCriteria, ISearchCase } from "../../../model/model";
import { useMessages } from "../../../context/MessagesContext";
import { t } from "i18next";
import { ChatSearchbox } from "../ChatSearchbox";

interface IMessageExistingCaseViewProps extends ICommonProps {
    selectedCase: string;
    selectedDocument?: string;
    archiveOnExistingDocumentEnabled: boolean;
}
export interface IMessageExistingCaseViewRef {
    validate(): boolean;
}
export const MessageExistingCaseView = forwardRef<IMessageExistingCaseViewRef, IMessageExistingCaseViewProps>((props, ref) => {
    const { selectedCase, selectedDocument, archiveOnExistingDocumentEnabled } = props;
    const requiredErrorMessage = archiveOnExistingDocumentEnabled ? t('Case or document selection is required') : t('Case selection is required');
    const { messageState } = useMessages();
    const [searchErrorMessage, setSearchErrorMessage] = useState<string | undefined>(undefined);
    
    const [searchCaseCriteria] = useState<ISearchCase>(() => {
        const caseDocumentData = { ...messageState.caseDocumentData };
        if (typeof caseDocumentData?.caseNumber != 'undefined' && caseDocumentData?.caseNumber) {
            return { searchCriteria: caseDocumentData?.caseNumber, includeClosedCases: false };
        }

        if (typeof caseDocumentData?.documentNumber != 'undefined' && caseDocumentData?.documentNumber) {
            return { searchCriteria: caseDocumentData?.documentNumber, includeClosedCases: false };
        }

        return { searchCriteria: selectedCase ?? selectedDocument, includeClosedCases: false };
    });
    const searchCriteriaOptions = archiveOnExistingDocumentEnabled ? EnumSearchCriteria.All : EnumSearchCriteria.Case;
    const searchBoxWidth = archiveOnExistingDocumentEnabled ? 230 : 245;

    useImperativeHandle(ref, () => ({
        validate: () => {
            if (messageState.searchCriteria === EnumSearchCriteria.Case) {
                if (messageState.caseDocumentData.caseNumber)
                    return true;
            }
            else if (messageState.searchCriteria === EnumSearchCriteria.Document) {
                if (messageState.caseDocumentData.documentNumber)
                    return true;
            }
            setSearchErrorMessage(requiredErrorMessage);
            return false;
        }
    }));
    
    return <div className={classNames.messageExistingCaseViewContainer}>
        <div>
            <ChatSearchbox {...props}
                enumSearchCriteria={searchCriteriaOptions}
                includeClosedCases={false}
                searchText={searchCaseCriteria.searchCriteria}
                searchBoxWidth={searchBoxWidth}
                searchErrorMessage={searchErrorMessage} />
        </div>
    </div>;
});