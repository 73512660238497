import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { ICommonProps } from "../../ICommonProps";
import { classNames, dropdownStyles } from "../../../styles/MergeStyleSets";
import { Dropdown, IDropdownOption, TextField } from "@fluentui/react";
import { t } from "i18next";
import { Constants } from "../../../helpers/Constants";
import { useMessages } from "../../../context/MessagesContext";
import { MessagesActionType } from "../../../context/MessagesReducer";

export interface IMessageNewCaseViewRef {
    validate(): boolean;
}

export const MessageNewCaseView = forwardRef<IMessageNewCaseViewRef, ICommonProps>((props, ref) => {
    const { dataProvider } = props;
    const { messageState, dispatch } = useMessages();

    const [accessGroupOptions, setAccessGroupOptions] = useState<IDropdownOption[]>([]);
    const [showCaseTitleError, setShowCaseTitleError] = useState(false);
    const [selectedAccessGroup, setSelectedAccessGroup] = useState<number>();
    const [showAccessGroupError, setShowAccessGroupError] = useState(false);
    const requiredError = t('Required');

    const onCaseTitleChange = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        dispatch({ type: MessagesActionType.ArchiveMessages, payload: { caseDocumentData: { ...messageState.caseDocumentData, caseTitle: newValue } } });
        if (newValue?.trim() === "")
            setShowCaseTitleError(true);
        else
            setShowCaseTitleError(false);
    }, [dispatch, messageState.caseDocumentData]);

    useImperativeHandle(ref, () => ({
        validate: () => {
            let isValid = true;
            if (!messageState.caseDocumentData.caseTitle) {
                setShowCaseTitleError(true);
                isValid = false;
            }
            else
                setShowCaseTitleError(false);

            if (!messageState.caseDocumentData.accessGroup) {
                setShowAccessGroupError(true);
                isValid = false;
            }
            else
                setShowAccessGroupError(false);
            return isValid;
        }
    }));

    const onAccessGroupChange = useCallback((event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
        dispatch({ type: MessagesActionType.ArchiveMessages, payload: { caseDocumentData: { ...messageState.caseDocumentData, accessGroup: option?.text } } });
        setSelectedAccessGroup(Number(option?.key));
        setShowAccessGroupError(false);
    }, [dispatch, messageState.caseDocumentData]);

    const getAccessGroups = useCallback(() => {
        setAccessGroupOptions([]);
        dataProvider?.P360?.getAccessGroups().then((res) => {
            for (const element of res.data)
                setAccessGroupOptions(accessGroup => [...accessGroup, { key: element.Recno, text: element.Code }]);

            if(messageState.caseDocumentData.accessGroup)
                setSelectedAccessGroup(res.data.find(ag => ag.Code === messageState.caseDocumentData.accessGroup)?.Recno);
        });
    }, [dataProvider?.P360, messageState.caseDocumentData.accessGroup]);

    useEffect(() => {
        getAccessGroups();
    }, [getAccessGroups]);
    return <div className={classNames.messageNewCaseViewContainer}>
        <div>
            <TextField label={t('Case title')}
                value={messageState.caseDocumentData.caseTitle}
                required
                multiline
                maxLength={Constants.CaseTitleMaxLength}
                resizable={false}
                onChange={onCaseTitleChange}
                errorMessage={showCaseTitleError ? requiredError : undefined} />
        </div>
        <div>
            <Dropdown placeholder={t('Select an option')}
                required
                selectedKey={selectedAccessGroup}
                label={t('Access group')}
                options={accessGroupOptions}
                styles={dropdownStyles}
                onChange={onAccessGroupChange}
                errorMessage={showAccessGroupError ? requiredError : undefined} />
        </div>
    </div>;
});