export class Constants {
    static Capabilities = {
        Get360DocumentsWithFiles: "Get360DocumentsWithFiles",
        ImportFiles: "ImportFiles",
        DocumentsSearch: "DocumentsSearch",
        GetDocumentsByDocumentNumber: "GetDocumentsByDocumentNumber",
        GetHistory: "GetHistory",
        AppPermissionsEndpoints: "AppPermissionsEndpoints",
        ArchiveOnExistingDocument: "ArchiveOnExistingDocument",
        TruncateLongFileNames: "TruncateLongFileNames",
        DenyRestricted: "DenyRestricted",
        DisallowCaseCreation: "DisallowCaseCreation",
        ChatArchiving: "ChatArchiving"
    };

    static HomeDir = 'Home';
    static CaseTitleMaxLength = 254;
    static DocumentTitleMaxLength = 254;
    static FileNameMaxLength = 254;
    static FileNameMaxLengthWithoutBLTruncate = 200;
}