import { EnumSearchCriteria, ICaseDocumentRequest, IConversationMember, IMessageItem } from "../model/model";
export enum MessagesActionType {
    RefreshMessagesByApi = "REFRESH_MESSAGES_BY_API",
    RefreshMessagesByFilter = "REFRESH_MESSAGES_BY_FILTER",
    UpdateProfilePhoto = "UPDATE_PROFILE_PHOTO",
    ArchiveMessages = "ARCHIVE_MESSAGES",
    UpdateSearchCriteria = "UPDATE_SEARCH_CRITERIA",
    UpdateStepWizard = "UPDATE_STEP_WIZARD"
}

export interface IMessagesState {
    initialMessages: IMessageItem[];
    messages: IMessageItem[];
    conversationTitle: string;
    conversationMembers: IConversationMember[];
    caseDocumentData: ICaseDocumentRequest;
    searchCriteria: EnumSearchCriteria;
    stepWizard: number;
    isFailed: boolean;
}

export interface IMessagesPayload {
    data?: IMessageItem[];
    conversationTitle?: string;
    conversationMembers?: IConversationMember[];
    caseDocumentData?: ICaseDocumentRequest;
    searchCriteria?: EnumSearchCriteria;
    stepWizard?: number;
    isFailed?: boolean;
}

export interface IMessagesAction {
    type: MessagesActionType;
    payload?: IMessagesPayload
}

function MessagesReducer(state: IMessagesState, action: IMessagesAction): IMessagesState {
    const newState = { ...state };
    switch (action.type) {
        case MessagesActionType.RefreshMessagesByApi: {
            newState.messages = action.payload?.data ?? [];
            newState.initialMessages = action.payload?.data ?? [];
            newState.conversationTitle = action.payload?.conversationTitle ?? '';
            newState.conversationMembers = action.payload?.conversationMembers ?? [];
            newState.isFailed = action.payload?.isFailed ?? false;
            return newState;
        }
        case MessagesActionType.RefreshMessagesByFilter: {
            newState.messages = action.payload?.data ?? [];
            return newState;
        }
        case MessagesActionType.UpdateProfilePhoto: {
            newState.conversationMembers = action.payload?.conversationMembers ?? [];
            return newState;
        }
        case MessagesActionType.ArchiveMessages: {
            newState.caseDocumentData = { ...newState.caseDocumentData, ...action.payload?.caseDocumentData };
            return newState;
        }
        case MessagesActionType.UpdateSearchCriteria: {
            newState.searchCriteria = action.payload?.searchCriteria ?? EnumSearchCriteria.Case;
            return newState;
        }
        case MessagesActionType.UpdateStepWizard: {
            newState.stepWizard = action.payload?.stepWizard ?? 0;
            return newState;
        }
        default:
            return state;
    }
}

export default MessagesReducer;