import { FC, useCallback, useEffect, useState } from "react";
import { ICommonProps } from "../ICommonProps";
import { CheckboxVisibility, ConstrainMode, DetailsListLayoutMode, IColumn, ISelection, Selection, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IMessageItem } from "../../model/model";
import ReadMore from "./ReadMore";
import { FormatDateTime } from "../../helpers/FormatDateTime";
import { useMessages } from "../../context/MessagesContext";
import { useTeams } from "../../context/TeamsContext";
import ProfilePhoto from "./ProfilePhoto";
import Attachments from "./Attachments";

interface IChatListProps extends ICommonProps {
    selectedMessages?: IMessageItem[];
    showPhotoShimmer?: boolean;
    showProfilePhoto?: boolean;
    includeAttachments?: boolean;
    setSelectedMessages?: (files: IMessageItem[]) => void;
    // use only for testing purposes
    skipViewportMeasures?: boolean;
}

const ChatList: FC<IChatListProps> = (props) => {
    const { t } = useTranslation();
    const { teamsState } = useTeams();
    const { messageState } = useMessages();
    const [messages, setMessages] = useState<IMessageItem[]>([]);
    const [columns, setColumns] = useState<IColumn[]>([]);

    const { selectedMessages, showPhotoShimmer, showProfilePhoto, includeAttachments, setSelectedMessages, skipViewportMeasures } = props;

    const onRenderLastModifiedDateTime = useCallback((item: IMessageItem) => {
        return <span>{FormatDateTime.convert(item.lastModifiedDateTime as Date)}</span>;
    }, []);

    const onRenderMessage = useCallback((item: IMessageItem) => {
        return <ReadMore content={item.bodyContent} />;
    }, []);

    const onRenderFromUser = useCallback((item: IMessageItem) => {
        return <ProfilePhoto showPhotoShimmer={showPhotoShimmer} showProfilePhoto={showProfilePhoto} item={item} />;
    }, [showPhotoShimmer, showProfilePhoto]);

    const onRenderAttachments = useCallback((item: IMessageItem) => {
        return <Attachments item={item} />;
    }, []);

    const [selection] = useState<ISelection<IMessageItem>>(() => new Selection<IMessageItem>({
        onSelectionChanged: () => {
            if (setSelectedMessages)
                setSelectedMessages(selection.getSelection());
        },
        items: selectedMessages ?? [],
        getKey: (item: IMessageItem) => item.id as string
    }));

    const getProfilePhoto = useCallback(() => {
        const messages = [...messageState.messages];
        const members = [...messageState.conversationMembers];
        const messagesWithProfilePhoto = messages.map((message) => {
            const member = members.find((member) => member.UserId === message.fromUserId);
            if (member?.Photo) {
                message.fromUserPhoto = member.Photo;
            }
            return message;
        });
        setMessages(messagesWithProfilePhoto);
    }, [messageState.conversationMembers, messageState.messages]);

    useEffect(() => {
        if (selectedMessages?.length === 0) {
            selection.setAllSelected(false);
        }
        const columns = [
            { key: 'lastModified', name: t('Date/Time'), fieldName: 'lastModifiedDateTime', minWidth: 80, maxWidth: 180, isResizable: true, onRender: onRenderLastModifiedDateTime },
            { key: 'fromUser', name: t('Name'), minWidth: 120, maxWidth: 180, onRender: onRenderFromUser },
            { key: 'message', name: t('Message'), minWidth: 200, isResizable: true, onRender: onRenderMessage }
        ];
        if (includeAttachments) {
            columns.splice(2, 0, { key: 'attachment', name: t('Attachments'), minWidth: 120, maxWidth: 180, onRender: onRenderAttachments });
        }
        else {
            const attachmentColumn = columns.find(x => x.key === 'attachment');
            if (attachmentColumn) {
                columns.splice(columns.indexOf(attachmentColumn), 1);
            }
        }
        setColumns(columns);
        getProfilePhoto();
    }, [getProfilePhoto, onRenderAttachments, onRenderFromUser, onRenderLastModifiedDateTime, onRenderMessage, selectedMessages?.length, selection, includeAttachments, t]);

    return <ShimmeredDetailsList key={teamsState.userContext?.chat?.id}
        setKey={teamsState.userContext?.chat?.id}
        items={messages}
        columns={columns}
        selection={selection}
        checkboxVisibility={CheckboxVisibility.always}
        selectionMode={SelectionMode.multiple}
        enableShimmer={messages.length === 0 && !messageState.isFailed}
        layoutMode={DetailsListLayoutMode.justified}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="Select row"
        ariaLabelForShimmer={t('Loading...')}
        selectionPreservedOnEmptyClick={true}
        constrainMode={ConstrainMode.unconstrained}
        skipViewportMeasures={skipViewportMeasures}
    />;
};

export default ChatList;